.kg-collection-card {
  width: 100%;
  margin-top: 6vmin;
}

.kg-collection-card + * {
  margin-top: 6vmin;
}

.kg-collection-card-title {
  margin: 0.8rem 0 1.6rem 0;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

a.kg-collection-card-post-wrapper {
  text-decoration: none;
  color: var(--text-color);
}

a.kg-collection-card-post-wrapper:hover {
  opacity: 1;
}

.kg-collection-card-post {
  display: flex;
  gap: 3.2rem;
}

.kg-collection-card-img {
  position: relative;
  aspect-ratio: 3/2;
}

.kg-collection-card-img img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

a.kg-collection-card-post-wrapper:hover img {
  opacity: 0.92;
  transition: all 0.2s ease;
}

.kg-collection-card-content {
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
}

h2.kg-collection-card-post-title {
  margin: 0;
  font-size: 2.4rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

p.kg-collection-card-post-excerpt {
  margin-top: 1.2rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.4;
}

.kg-collection-card-post-meta {
  display: flex;
  opacity: 0.5;
  margin-top: 1.2rem;
  font-size: 1.3rem;
  font-weight: 500;
}

/* List layout */

.kg-collection-card-list {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
}

@media (max-width: 767px) {
  .kg-collection-card-list .kg-collection-card-post {
    flex-direction: column;
  }
}

.kg-collection-card-list .kg-collection-card-img {
  flex: 0 0 30%;
}

/* Grid layout */

.kg-collection-card-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2.4rem;
}

@media (min-width: 640px) {
  .kg-collection-card-grid:not(.columns-1) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .kg-collection-card-grid:not(.columns-1):not(.columns-2) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 3.2rem;
  }

  .kg-collection-card-grid.columns-1 {
    gap: 4.8rem;
  }

  .kg-collection-card-grid.columns-2 {
    gap: 4rem;
  }
}

@media (min-width: 1280px) {
  .kg-collection-card-grid:not(.columns-1):not(.columns-2):not(.columns-3) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.kg-collection-card-grid .kg-collection-card-post {
  flex-direction: column;
  gap: 1.2rem;
}

@media (min-width: 1024px) {
  .kg-collection-card-grid.columns-1 .kg-collection-card-post {
    gap: 2rem;
  }

  .kg-collection-card-grid.columns-2 .kg-collection-card-post {
    gap: 1.6rem;
  }
}

.kg-collection-card-grid.columns-1 .kg-collection-card-img,
.kg-collection-card-grid.columns-2 .kg-collection-card-img {
  aspect-ratio: 16/9;
}

.kg-collection-card-grid .kg-collection-card-content {
  font-size: 1.5rem;
}

@media (min-width: 640px) {
  .kg-collection-card-grid .kg-collection-card-content {
    font-size: 1.6rem;
  }
}

@media (min-width: 1024px) {
  .kg-collection-card-grid.columns-1 .kg-collection-card-content {
    font-size: 1.8rem;
  }

  .kg-collection-card-grid.columns-3 .kg-collection-card-content,
  .kg-collection-card-grid.columns-4 .kg-collection-card-content {
    font-size: 1.5rem;
  }
}

.kg-collection-card-grid h2.kg-collection-card-post-title {
  font-size: 1.7rem;
}

.kg-collection-card-grid .kg-collection-card-post-meta {
  font-size: 1.25rem;
}

@media (min-width: 640px) {
  .kg-collection-card-grid h2.kg-collection-card-post-title {
    font-size: 1.9rem;
  }

  .kg-collection-card-grid.columns-1 h2.kg-collection-card-post-title {
    font-size: 2.4rem;
  }

  .kg-collection-card-grid:not(.columns-3):not(.columns-4)
    .kg-collection-card-post-meta {
    font-size: 1.3rem;
  }
}

@media (min-width: 1024px) {
  .kg-collection-card-grid.columns-1 h2.kg-collection-card-post-title {
    font-size: 3.6rem;
  }

  .kg-collection-card-grid.columns-2 h2.kg-collection-card-post-title {
    font-size: 2.4rem;
  }

  .kg-collection-card-grid.columns-1 .kg-collection-card-post-meta {
    font-size: 1.4rem;
  }
}

@media (min-width: 1280px) {
  .kg-collection-card-grid.columns-4 h2.kg-collection-card-post-title {
    font-size: 1.7rem;
  }
}
