@import "vars.scss";

@font-face {
    font-family:"Itim";
    src: url("/fonts/Itim-Regular.ttf");
}

@font-face {
    font-family: 'RocherColor';
    src: url('/fonts/RocherColorGX-3f0e7056.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    
}

@font-face {
    font-family: 'Playwrite';
    src: url('/fonts/PlaywriteNO-VariableFont_wght.ttf') format('truetype');
    
}

@import "page.scss";
@import "ghost.scss";

html, body {
    min-height: 100%;
    height: 100vh;
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
}

p code, 
li code {
    background-color: #eee;
    color: #e40f0f;
    
    display: inline-block;
    padding: 0.1rem 0.3rem;
    border-radius: 0.3rem;
    font-family: 'Courier New', Courier, monospace;
}


/* Pages */

/* Map size */
.maplibregl-canvas-container {
    height: 100%;
}

/* Map controls */
.maplibregl-ctrl-styles {
    padding:4px 8px;
    font-size:1.3em;
    cursor: pointer;
}

.maplibregl-ctrl-styles:hover {
    background-color: rgb(246, 246, 246);

}

.fr-icon-road-map-fill i {
    display: inline-block;
    
}

.maplibregl-ctrl-styles i::before {
    max-width: 16px;
    margin-right:5px;
    
}

.maplibregl-ctrl-styles-label {
    font-weight: 500;
}


/* Display utils */

.none {
    display: none;
}



@media (min-width: $bp-sm) {
    .sm-none {
        display: none;
    }
    .sm-block {
        display: block;
    }
}

@media (min-width: $bp-md) {
    .md-none {
        display: none;
    }
    .md-block {
        display: block;
    }
}

@media (min-width: $bp-lg) {
    .lg-none {
        display: none;
    }
    .lg-block {
        display: block;
    }
}

.resp-image {
    max-width: 100%;
    height: auto;
}

/* Text utils */
.text-center {
    text-align: center;
}

.md-text-left {
    @media (min-width: $bp-md) {
        text-align: left;
    }
}
.text-blue {
    color:#000091;
}

/* Surligneur */

.stab {
    background-color: #f4f6ff;
    padding: 0.1rem 0.3rem;
    border-radius: 0.2rem;
}

.stab--blue {
    background-color: #e8edff;
}
.stab--green {
    background-color: #dffee6;
}
.stab--yellowStrong {
    background-color: #fbe769;
}
.stab--yellow {
    background-color: #fceeac;
}

.definition-card {
    .fr-card__end, .fr-card__start {
        display: none;
    }
}