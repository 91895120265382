.kg-gallery-card,
.kg-gallery-card * {
  box-sizing: border-box;
}

.kg-gallery-card,
.kg-image-card {
  --gap: 1.2rem;
}

.kg-image-card:not(.kg-card-hascaption) + .kg-image-card,
.kg-image-card:not(.kg-card-hascaption) + .kg-gallery-card,
.kg-gallery-card:not(.kg-card-hascaption) + .kg-image-card,
.kg-gallery-card:not(.kg-card-hascaption) + .kg-gallery-card {
  margin-top: var(--gap);
}

.kg-gallery-container {
  position: relative;
}

.kg-gallery-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.kg-gallery-image img {
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
}

.kg-gallery-row:not(:first-of-type) {
  margin: var(--gap) 0 0;
}

.kg-gallery-image:not(:first-of-type) {
  margin: 0 0 0 var(--gap);
}

@media (max-width: 600px) {
  .kg-gallery-card,
  .kg-image-card {
    --gap: 0.6rem;
  }
}
