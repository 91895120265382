.kg-audio-card,
.kg-audio-card * {
  box-sizing: border-box;
}

.kg-audio-card {
  display: flex;
  width: 100%;
  min-height: 96px;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(124, 139, 154, 0.25);
}

.kg-audio-card + .kg-audio-card {
  margin-top: 1em;
}

.kg-audio-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  min-width: 80px;
  margin: 8px;
  background: transparent;
  object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: 2px;
}

.kg-audio-thumbnail.placeholder {
  background: var(--ghost-accent-color);
}

.kg-audio-thumbnail.placeholder svg {
  width: 24px;
  height: 24px;
  fill: white;
}

.kg-audio-player-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  --seek-before-width: 0%;
  --volume-before-width: 100%;
  --buffered-width: 0%;
}

.kg-audio-title {
  width: 100%;
  margin: 8px 0 0 0;
  padding: 8px 12px;
  border: none;
  font-family: inherit;
  font-size: 1.15em;
  font-weight: 700;
  line-height: 1.15em;
  background: transparent;
}

.kg-audio-player {
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 8px 12px;
}

.kg-audio-current-time {
  min-width: 38px;
  padding: 0 4px;
  font-family: inherit;
  font-size: 0.85em;
  font-weight: 500;
  line-height: 1.4em;
  white-space: nowrap;
}

.kg-audio-time {
  width: 56px;
  color: #ababab;
  font-family: inherit;
  font-size: 0.85em;
  font-weight: 500;
  line-height: 1.4em;
  white-space: nowrap;
}

.kg-audio-duration {
  padding: 0 4px;
}

.kg-audio-play-icon,
.kg-audio-pause-icon {
  position: relative;
  bottom: 1px;
  padding: 0px 4px 0 0;
  font-size: 0;
  background: transparent;
}

.kg-audio-hide {
  display: none !important;
}

.kg-audio-play-icon svg,
.kg-audio-pause-icon svg {
  width: 14px;
  height: 14px;
  fill: currentColor;
}

.kg-audio-seek-slider {
  flex-grow: 1;
  margin: 0 4px;
}

@media (max-width: 640px) {
  .kg-audio-seek-slider {
    display: none;
  }
}

.kg-audio-playback-rate {
  min-width: 37px;
  padding: 0 4px;
  font-family: inherit;
  font-size: 0.85em;
  font-weight: 600;
  line-height: 1.4em;
  text-align: left;
  background: transparent;
  white-space: nowrap;
}

@media (max-width: 640px) {
  .kg-audio-playback-rate {
    padding-left: 8px;
  }
}

.kg-audio-mute-icon,
.kg-audio-unmute-icon {
  position: relative;
  bottom: -1px;
  padding: 0 4px;
  font-size: 0;
  background: transparent;
}

@media (max-width: 640px) {
  .kg-audio-mute-icon,
  .kg-audio-unmute-icon {
    margin-left: auto;
  }
}

.kg-audio-mute-icon svg,
.kg-audio-unmute-icon svg {
  width: 16px;
  height: 16px;
  fill: currentColor;
}

.kg-audio-volume-slider {
  width: 80px;
}

@media (max-width: 400px) {
  .kg-audio-volume-slider {
    display: none;
  }
}

.kg-audio-seek-slider::before {
  content: '';
  position: absolute;
  left: 0;
  width: var(--seek-before-width) !important;
  height: 4px;
  cursor: pointer;
  background-color: currentColor;
  border-radius: 2px;
}

.kg-audio-volume-slider::before {
  content: '';
  position: absolute;
  left: 0;
  width: var(--volume-before-width) !important;
  height: 4px;
  cursor: pointer;
  background-color: currentColor;
  border-radius: 2px;
}

/* Resetting browser styles
/* --------------------------------------------------------------- */

.kg-audio-player-container input[type='range'] {
  position: relative;
  -webkit-appearance: none;
  background: transparent;
}

.kg-audio-player-container input[type='range']:focus {
  outline: none;
}

.kg-audio-player-container input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.kg-audio-player-container input[type='range']::-ms-track {
  cursor: pointer;
  border-color: transparent;
  color: transparent;
  background: transparent;
}

.kg-audio-player-container button {
  display: flex;
  align-items: center;
  border: 0;
  cursor: pointer;
}

.kg-audio-player-container input[type='range'] {
  height: auto;
  padding: 0;
  border: 0;
}

/* Chrome & Safari styles
/* --------------------------------------------------------------- */

.kg-audio-player-container input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: rgba(124, 139, 154, 0.25);
  border-radius: 2px;
}

.kg-audio-player-container input[type='range']::-webkit-slider-thumb {
  position: relative;
  box-sizing: content-box;
  width: 13px;
  height: 13px;
  margin: -5px 0 0 0;
  border: 0;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.08),
    0 1px 4px rgba(0, 0, 0, 0.24);
}

.kg-audio-player-container input[type='range']:active::-webkit-slider-thumb {
  transform: scale(1.2);
}

/* Firefox styles
/* --------------------------------------------------------------- */

.kg-audio-player-container input[type='range']::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: rgba(124, 139, 154, 0.25);
  border-radius: 2px;
}

.kg-audio-player-container input[type='range']::-moz-range-progress {
  background: currentColor;
  border-radius: 2px;
}

.kg-audio-player-container input[type='range']::-moz-range-thumb {
  box-sizing: content-box;
  width: 13px;
  height: 13px;
  border: 0;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.08),
    0 1px 4px rgba(0, 0, 0, 0.24);
}

.kg-audio-player-container input[type='range']:active::-moz-range-thumb {
  transform: scale(1.2);
}

/* Edge & IE styles
/* --------------------------------------------------------------- */

.kg-audio-player-container input[type='range']::-ms-track {
  width: 100%;
  height: 3px;
  border: solid transparent;
  color: transparent;
  cursor: pointer;
  background: transparent;
}

.kg-audio-player-container input[type='range']::-ms-fill-lower {
  background: #fff;
}

.kg-audio-player-container input[type='range']::-ms-fill-upper {
  background: currentColor;
}

.kg-audio-player-container input[type='range']::-ms-thumb {
  box-sizing: content-box;
  width: 13px;
  height: 13px;
  border: 0;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.08),
    0 1px 4px rgba(0, 0, 0, 0.24);
}

.kg-audio-player-container input[type='range']:active::-ms-thumb {
  transform: scale(1.2);
}
