.kg-button-card,
.kg-button-card * {
  box-sizing: border-box;
}

.kg-button-card {
  display: flex;
  position: static;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.kg-button-card.kg-align-left {
  justify-content: flex-start;
}

.kg-button-card a.kg-btn {
  display: flex;
  position: static;
  align-items: center;
  padding: 0 1.2em;
  height: 2.4em;
  line-height: 1em;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 0.95em;
  font-weight: 600;
  text-decoration: none;
  border-radius: 5px;
  transition: opacity 0.2s ease-in-out;
}

.kg-button-card a.kg-btn:hover {
  opacity: 0.85;
}

.kg-button-card a.kg-btn-accent {
  background-color: var(--ghost-accent-color);
  color: #fff;
}
