.kg-bookmark-card,
.kg-bookmark-card * {
  box-sizing: border-box;
}

.kg-bookmark-card,
.kg-bookmark-publisher {
  position: relative;
  /* width: 100%; */
}

.kg-bookmark-card a.kg-bookmark-container,
.kg-bookmark-card a.kg-bookmark-container:hover {
  display: flex;
  text-decoration: none;
  border-radius: 3px;
  border: 1px solid rgb(124 139 154 / 25%);
  overflow: hidden;
  color: inherit;
}

.kg-bookmark-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  overflow: hidden;
}

.kg-bookmark-title {
  font-size: 1.5rem;
  line-height: 1.4em;
  font-weight: 600;
}

.kg-bookmark-description {
  display: -webkit-box;
  font-size: 1.4rem;
  line-height: 1.5em;
  margin-top: 3px;
  font-weight: 400;
  max-height: 44px;
  overflow-y: hidden;
  opacity: 0.7;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.kg-bookmark-metadata {
  display: flex;
  align-items: center;
  margin-top: 22px;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  white-space: nowrap;
}

.kg-bookmark-metadata > *:not(img) {
  opacity: 0.7;
}

.kg-bookmark-icon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.kg-bookmark-author,
.kg-bookmark-publisher {
  display: inline;
}

.kg-bookmark-publisher {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 240px;
  white-space: nowrap;
  display: block;
  line-height: 1.65em;
}

.kg-bookmark-metadata > span:nth-of-type(2) {
  font-weight: 400;
}

.kg-bookmark-metadata > span:nth-of-type(2):before {
  content: '•';
  margin: 0 6px;
}

.kg-bookmark-metadata > span:last-of-type {
  overflow: hidden;
  text-overflow: ellipsis;
}

.kg-bookmark-thumbnail {
  position: relative;
  flex-grow: 1;
  min-width: 33%;
}

.kg-bookmark-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 2px 2px 0;
}
