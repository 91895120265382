
// Sections

.pageContainer {
    padding-top:3rem;
    padding-bottom:3rem;
    max-width: 1000px;
}

.section {
    padding-bottom: 2rem;

    @media (min-width: $bp-md) {
        //margin-bottom: 4rem;
        padding-bottom: 2rem;
        padding-top:2rem;
    }

    &.section__big {
    
        padding-bottom: 3rem;

        @media (min-width: $bp-md) {
            //margin-bottom: 4rem;
            padding-bottom: 3rem;
            padding-top:3rem;
        }

    }
}



.section:first-child {
    padding-top: 0;
}
.section:last-child {
    padding-bottom: 0;
}

.section__titleblock {
    margin-bottom: 2rem;
}
.section__title {
    text-align: center;
    margin-bottom: 0.2em;
}
.section__subtitle {
    text-align: center;
    font-weight: 600;
    font-size: 1.2em;
}

// Color blocks

.block {
    
    padding:30px;
    display: flex;
    flex-direction: column;

    form {
        margin-bottom: 2rem;
        
    }

    ul {
        list-style-position: outside;
        &:last-child {
            margin-bottom: 0;
        }
    }
    li {
        margin-bottom:.4em;
    }

}

.block--smallNewsletterShell {
    padding-bottom:10px;
}

.block--fill {
    height: 100%;
}

.blockList {
    li {
        margin-bottom: .8rem;
    }
}

.blockLinkShell {
    margin-top: .6rem;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;

    &.blockLinkShell--noGrow {
        flex-grow: 0;
    }
    
}


.block--blue {
    background-color: #dde5ff;

    .blockTitle, .blockSubtitle {
        color:#1d2437;
    }   
    
}

.block--white {
    background-color: #fff;

    .blockTitle, .blockSubtitle {
        color:inherit;
    }   
    
}

.block--yellow {
    background-color: #fef7da;

    .blockTitle, .blockSubtitle {
        color:#3f3a20;
    }   
    
}

.block--red {
    background-color: #ffbeb4;
    .blockTitle, .blockSubtitle {
        color:#5d2c20;
    }   
}
.block--archipel {
    background-color: #e5fbfd;
    .blockTitle, .blockSubtitle {
        color:#233e41;
    }
}

.block--pink {
    background-color: #fef3fd;
    .blockTitle, .blockSubtitle {
        color:#502e4d;
    }
}

.block--green {
    background-color: #e6feda;

    .blockTitle, .blockSubtitle {
        color:#2a401a;
    }   
}

.block--paleGreen {
    background-color: #e6feda;

    .blockTitle, .blockSubtitle {
        color:#18753c;
    }   
}

.block--paleBlue {
    background-color: #e3e3fd;

    .blockTitle, .blockSubtitle {
        color:#000091;
    }   
}


.blockTitle {
    font-size: 1.6em;
    margin-bottom: .3rem;
}
.blockSubtitle {
    font-size: 1.05em;
    //font-weight: 600;
    opacity: .90;
}